<template>
    <SpaceDataList :spaceData="datasetSpaces" :spaceType="spaceTypes.VENDOR_SPACE" spaceIcon="mdi-database" headerTitle="Datasets"></SpaceDataList>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
const SpaceDataList = () => import('../components/TheHomeSpaceTable')

export default {
    mixins: [enumsData],
    components: { SpaceDataList },
    computed: {
        ...mapState('orgStore', ['datasetSpaces'])
    }
}
</script>
